// KEEP THIS FIRST
import "~/javascripts/polyfils";

// LIBRARIES
import "~/javascripts/jquery";
import "foundation-sites";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/datepicker";
import "~/javascripts/rails";

// OUR FILES
import "~/javascripts/annual_survey";
import "~/javascripts/api_keys";
import "~/javascripts/banner";
import "~/javascripts/cookies";
import "~/javascripts/dropdown_menu";
import "~/javascripts/external_links";
import "~/javascripts/mobile-nav";
import "~/javascripts/new_user_record";
import "~/javascripts/recaptcha.js";
import "~/javascripts/user_images";
import "~/javascripts/user_records";
import "~/javascripts/area_chart";
import "~/javascripts/explore";

$(function () {
  $(document).foundation();
});
