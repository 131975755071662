import { some, remove } from "lodash";

function upsertGetParameter(name, value) {
  var str = location.search;
  if (new RegExp("[&?]" + name + "([=&].+)?$").test(str)) {
    str = str.replace(new RegExp("(?:[&?])" + name + "[^&]*", "g"), "");
  }
  str += "&";
  str += name + "=" + value;
  str = "?" + str.slice(1);
  return location.origin + location.pathname + str + location.hash;
}

$(function () {
  document
    .querySelector('.user-records select[name="per_page"]')
    ?.addEventListener("change", function (e) {
      let per_page = e.target.value;
      window.location = upsertGetParameter("per_page", per_page);
    });
  document
    .querySelector(".user-records .user-record__sortable")
    ?.addEventListener("click", function (e) {
      let direction = e.target.getAttribute("aria-sort");
      window.location = upsertGetParameter(
        "direction",
        direction == "desc" ? "asc" : "desc"
      );
    });

  var storyRecordIds = $(".js-story-record-ids").data("state");
  var storyCounts = $(".js-story-state").data("state");
  var storyNames = $(".js-story-names").data("state");

  var addToStoryFormContainer = `<div class="user-record__add-to-story__widget__content" data-record-id="RECORD_ID">
      <div class="grid-x" id="form-container"></div>
    </div>`;

  var addToStoryInput = `<div class="medium-10 small-10 cell">
      <input
        type="checkbox"
        name="STORY_NAME_TAG"
        id="STORY_NAME"
        value="STORY_ID"
        data-record-id="RECORD_ID"
        data-category="[&quot;Articles&quot;]"
        data-story-name="STORY_NAME_TAG"
        data-story-id="STORY_ID"
        data-item-id="ITEM_ID"
        class="user-record__add-to-story__widget__content__checkbox"
        checked />
      <label for="STORY_NAME">STORY_NAME_TAG</label>
    </div>
    <a class="medium-2 small-2 cell" href="/stories/STORY_ID">STORY_ITEM_COUNT items</a>`;

  function updateItemCount(storyId) {
    $($("a[href$=" + storyId + "]")).text(storyCounts[storyId] + " items");
  }

  function removeAddToStoryForm() {
    $(".user-record__add-to-story__widget__content").remove();
  }

  function showRecordAddToStoryForm(recordElement) {
    var recordId = $(recordElement).data("recordId");
    $(recordElement).parent().append(generateAddtoStoryForm(recordId));
  }

  function getStoryItemId(recordId, storyId) {
    return storyRecordIds[storyId].find(function (storyRecords) {
      return storyRecords.record_id === recordId;
    }).story_item_id;
  }

  function generateAddtoStoryForm(recordId) {
    var formContainer = $.parseHTML(
      addToStoryFormContainer.replace(/RECORD_ID/g, recordId)
    );
    var stories = $(".js-story-state").data("state");

    for (var storyId in stories) {
      var storyItemId;
      var recordInStory = some(storyRecordIds[storyId], {
        record_id: recordId,
      });
      var storyName = storyNames[storyId];
      var newAddToStoryInput = addToStoryInput
        .replace(/STORY_ID/g, storyId)
        .replace(/RECORD_ID/g, recordId)
        .replace(/STORY_ITEM_COUNT/g, stories[storyId])
        .replace(/STORY_NAME_TAG/g, storyName)
        .replace(/STORY_NAME/g, storyName.toLowerCase().replace(/\s/g, "_"));
      if (!recordInStory) {
        newAddToStoryInput = newAddToStoryInput.replace("checked", "");
      } else {
        storyItemId = getStoryItemId(recordId, storyId);
        newAddToStoryInput = newAddToStoryInput.replace(/ITEM_ID/, storyItemId);
      }
      $(formContainer).find("#form-container").append(newAddToStoryInput);
    }

    return formContainer;
  }

  function generatePayload(recordId, title, description, category) {
    return {
      item: {
        type: "embed",
        sub_type: "record",
        record_id: Number(recordId),
        content: {
          id: Number(recordId),
          record_id: Number(recordId),
          title: title,
          display_collection: "Topic explorer resources",
          image_url: "",
          description: description,
          category: category,
        },
        meta: {
          align_mode: 0,
        },
      },
    };
  }

  function bindAddMyRecordsToStories() {
    $("#user-records-table").on(
      "click",
      ".user-record__add-to-story__widget__plus-icon",
      function () {
        removeAddToStoryForm();
        showRecordAddToStoryForm(this);
      }
    );

    // close form if click outside the form
    $(document).on("click touch", function (event) {
      if (
        !$(event.target)
          .parents()
          .addBack()
          .is(".user-record__add-to-story__widget__content")
      ) {
        if (
          !$(event.target).hasClass(
            "user-record__add-to-story__widget__plus-icon"
          )
        ) {
          removeAddToStoryForm();
        }
      }
    });

    $("#user-records-table").on(
      "click",
      ".user-record__add-to-story__widget__content__checkbox",
      function () {
        var that = $(this);
        var storyId = that.val();
        var recordId = that.data("recordId");

        if (that.is(":checked")) {
          // Disable checkbox being checked
          that.attr("disabled", true);

          // Record Attributes
          var title = $("tr#" + recordId).data("title");
          var description = $("tr#" + recordId).data("description");
          var category = $("tr#" + recordId).data("category");

          var payload = generatePayload(recordId, title, description, category);

          fetch("/api/stories/" + storyId + "/items", {
            body: JSON.stringify(payload),
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (myJson) {
              $(that).attr("data-item-id", myJson.id);

              // Enable checkbox being checked
              that.attr("disabled", false);

              storyCounts[storyId] += 1;
              storyRecordIds[storyId].push({
                record_id: recordId,
                story_item_id: myJson.id,
              });
              updateItemCount(storyId);

              $(
                ".user-record__add-to-story__widget__plus-icon[data-record-id=" +
                  recordId +
                  "]"
              ).addClass(
                "user-record__add-to-story__widget__plus-icon--ticked"
              );
            });
        } else {
          // Disable checkbox being checked
          that.attr("disabled", true);

          var itemId = that.data("itemId");

          fetch("/api/stories/" + storyId + "/items/" + itemId, {
            body: "",
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
            },
            credentials: "include",
          }).then(function (response) {
            // Enable checkbox being checked
            that.attr("disabled", false);

            storyCounts[storyId] -= 1;
            remove(storyRecordIds[storyId], function (storyRecord) {
              return storyRecord.record_id === recordId;
            });
            updateItemCount(storyId);

            if (
              $(
                ".user-record__add-to-story__widget__content[data-record-id=" +
                  recordId +
                  "] input:checked"
              ).length === 0
            ) {
              $(
                ".user-record__add-to-story__widget__plus-icon[data-record-id=" +
                  recordId +
                  "]"
              ).removeClass(
                "user-record__add-to-story__widget__plus-icon--ticked"
              );
            }
          });
        }
      }
    );
  }

  bindAddMyRecordsToStories();
});
