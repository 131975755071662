import { isInternalLink } from "~/src/utils/isExternalLink";

$(function () {
  $(".main a").each(function (_idx, link) {
    var href = $(link).attr("href");

    if (
      isInternalLink(href) ||
      window.location.pathname.startsWith("/records") // Don't add external icons to links in the records page
      ||
      window.location.pathname.startsWith('/explore')
      ||
      window.location.pathname.startsWith('/stories')
    ) {
      return;
    }

    $(link).attr("target", "_blank");
    $(link).addClass("external-link");
  });
});
